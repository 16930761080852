<template>
  <div class="vendor-contracts">
    <v-sheet class="mb-4 px-6 py-6 outlined" elevation="1">
      <div class="d-flex align-center justify-space-between mb-4">
        <span class="title">
          {{ $t('vendors.contractsTitle', [contracts.active.length]) }}
        </span>
      </div>
      <v-alert v-if="error" type="error">
        {{ $t('vendors.contractsError') }}
      </v-alert>
      <Spinner v-if="loading" class="mx-auto" />
      <div
        v-else-if="!contracts.active.length"
        class="pa-4 rounded aon-gray-07"
      >
        <span>{{ $t('vendors.noContracts') }}</span>
      </div>
      <template v-else>
        <VendorContract
          v-for="contract in contracts.active"
          :id="contract.id"
          :key="contract.id"
          :contract="contract"
          :vendor="vendor"
          :notification-params="notificationParams"
          :insurance-totals="insuranceTotals"
          @updateContract="initialize"
          @initialize="initialize"
        />
      </template>
      <div v-if="!loading && contracts.inactive.length" class="mt-4">
        <div class="mx-4">
          <button
            class="aon-gray-02--text button-toggle r-90"
            :class="{ 'button-toggle--active': showInactive }"
            type="button"
            @click="showInactive = !showInactive"
          >
            <v-icon class="mr-1" small>fal fa-angle-right</v-icon>
            {{ $t('vendors.contractsInactive') }}
          </button>
        </div>
        <div v-show="showInactive" class="mt-4">
          <VendorContract
            v-for="contract in contracts.inactive"
            :key="contract.id"
            :contract="contract"
            :vendor="vendor"
            :insurance-totals="insuranceTotals"
            :inactive="true"
            @updateContract="initialize"
            @initialize="initialize"
          />
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDateFromUTC } from '@aon/cfs-utils';
import {
  ACCEPTED,
  DECLINED,
  TERMINATED,
} from '@/lib/constants/contract-statuses';
import VendorContract from '@/components/Vendors/VendorContract';

export default {
  name: 'VendorContracts',
  components: {
    VendorContract,
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
    notificationParams: {
      type: Object,
      default: null,
    },
    insuranceTotals: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      acceptedStatus: ACCEPTED,
      showInactive: false,
      addContractDialogOpen: false,
    };
  },
  computed: {
    ...mapGetters('contracts', ['getContractsById']),
    contracts() {
      const contracts = {
        active: [],
        inactive: [],
      };

      this.getContractsById(this.vendorId).forEach(contract => {
        const hasActiveStatus = ![TERMINATED, DECLINED].includes(
          contract.status
        );
        const isCurrent =
          contract.evergreen || new Date() < new Date(contract.cease);
        const isActive = hasActiveStatus && isCurrent;

        contracts[isActive ? 'active' : 'inactive'].push({
          ...contract,
          evergreenText: contract.evergreen
            ? this.$t('vendors.evergreen.yes')
            : this.$t('vendors.evergreen.no'),
          evergreen: contract.evergreen,
          commenceText: getDateFromUTC(contract.commence),
          commence: contract.commence,
          ceaseText: contract.cease ? getDateFromUTC(contract.cease) : 'N/A',
          cease: contract.cease,
          status: contract.status,
        });
      });

      return contracts;
    },
    vendorId() {
      return this.vendor.vendorId;
    },
    contractVendor() {
      return {
        id: this.vendor.vendorId,
        name: this.vendor.vendorName,
        email: this.vendor.vendorEmail,
      };
    },
  },
  async created() {
    await this.getContracts();
    this.$AppEventBus.$on('updatedContracts', this.getContracts);
    this.scrollTo();
  },
  beforeDestroy() {
    this.$AppEventBus.$off('updatedContracts', this.getContracts);
  },
  methods: {
    async initialize() {
      this.$emit('initialize');
      await this.getContracts();
    },
    scrollTo() {
      if (
        this.notificationParams?.active &&
        document.getElementById(`${this.notificationParams.contractId}`)
      ) {
        this.$nextTick(() =>
          document
            .getElementById(`${this.notificationParams.contractId}`)
            .scrollIntoView({ behavior: 'smooth', block: 'start' })
        );
      }
    },
    async getContracts() {
      this.loading = true;

      try {
        await this.$store.dispatch(
          'contracts/getContractsForVendor',
          this.vendorId
        );
      } catch {
        this.error = true;
      }

      this.loading = false;
    },
    addContract() {
      this.addContractDialogOpen = true;
    },
  },
};
</script>

<style lang="scss">
.vendor-contracts {
  .v-sheet {
    .title {
      font-weight: 700;
      font-size: 1rem;
      color: $aon-peacock;
    }
  }
}
</style>
