<template>
  <div class="agreement-total">
    <div
      :class="statusColor"
      class="px-2 compliance-status compliance-sidebar"
    ></div>
    <div class="agreement-total-details">
      <div class="pl-1 d-flex justify-space-between">
        <div>
          <h5>
            {{ $t(`mappings.insurance.${insuranceTotal.insuranceType}`) }}
          </h5>
          <div>
            {{ requirementText }}
            <v-tooltip
              v-if="showModifiedFlag"
              color="aon-peacock"
              bottom
              open-on-hover
              class="text-center d-inline-block mr-5"
            >
              <template v-slot:activator="{ on }">
                <i
                  class="fas fa-flag warning--text mr-2"
                  size="0.75rem"
                  v-on="on"
                >
                </i>
              </template>
              <span>{{ $t('vendors.adjustedRequirement') }}</span>
            </v-tooltip>
          </div>
        </div>
        <v-menu content-class="client-contract__action-menu" offset-y left>
          <template v-slot:activator="{ on }">
            <AppButton class="ml-2" variation="icon" v-on="on">
              <i class="fal fa-ellipsis-h" />
            </AppButton>
          </template>
          <v-list class="py-0">
            <v-list-item link>
              <v-list-item-title class="aon-teal--text">
                <AgreementRequirementModal
                  :insurance-total="insuranceTotal"
                  :contract="contract"
                  :vendor-id="contract.vendorId"
                  :vendor="vendor"
                />
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!!currentPolicyId" link>
              <v-list-item-title class="aon-teal--text">
                <AppButton
                  variation="ghost"
                  class="full-width mb-2"
                  prepend-icon="fal fa-paperclip"
                  :loading="loadingCoi"
                  @click="e => downloadCertificate()"
                >
                  {{ $t('vendors.viewCoi') }}
                </AppButton>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- NOTIFICATIONS -->
      <Alert
        v-if="unverifiedPolicy"
        class="mt-4 ml-4 mb-2 rounded outlined record-notification"
        persistent
        type="chat"
      >
        <div class="notification-border"></div>
        <template>
          <div
            class="d-flex justify-space-between align-center record-notification-content"
          >
            <template>
              <span class="aon-gray-01--text">
                {{ $t('vendors.insurance.notVerified') }}
              </span>
            </template>
          </div>
        </template>
      </Alert>
      <Alert
        v-if="subreqsMissing"
        class="mt-4 ml-4 mb-2 rounded outlined record-notification"
        persistent
        type="chat"
      >
        <div class="notification-border"></div>
        <div
          class="d-flex justify-space-between align-center record-notification-content"
        >
          <span class="aon-gray-01--text">
            {{ $t('vendors.insurance.subreqsMissing') }}
          </span>
        </div>
      </Alert>
      <Alert
        v-if="limitsMissing"
        class="mt-4 ml-4 mb-2 rounded outlined record-notification"
        persistent
        type="chat"
      >
        <div class="notification-border"></div>
        <div
          class="d-flex justify-space-between align-center record-notification-content"
        >
          <span class="aon-gray-01--text">
            {{ $t('vendors.insurance.limitsMissing') }}
          </span>
        </div>
      </Alert>
      <Alert
        v-if="expiredCoverage"
        class="mt-4 ml-4 mb-2 rounded outlined record-notification"
        persistent
        type="chat"
      >
        <div class="notification-border"></div>
        <div class="pt-2 record-notification-content">
          <div>
            {{ $t('vendors.insurance.willBeNonCompliant') }}
          </div>
          <div>
            {{ insuranceTotal.nonComplianceDate | longDate }}
          </div>
        </div>
      </Alert>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/insurancePolicies';
import { downloadDocument } from '@aon/cfs-insurance/src/api/document';
import AgreementRequirementModal from './AgreementRequirementModal';
import { formatRequirement } from '@/lib/helpers';

export default {
  name: 'InsuranceAgreementTotal',
  components: {
    AgreementRequirementModal,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    insuranceTotal: {
      type: Object,
      required: true,
    },
    pendingAdjustmentRequest: {
      type: Boolean,
      required: true,
    },
    subreqsMissing: {
      type: Boolean,
      required: true,
    },
    limitsMissing: {
      type: Boolean,
      required: true,
    },
    expiredCoverage: {
      type: Boolean,
      required: true,
    },
    unverifiedPolicy: {
      type: Boolean,
      required: true,
    },
    vendor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingCoi: false,
    };
  },
  computed: {
    currentPolicyId() {
      let policyId = undefined;
      if (!!this.insuranceTotal.similarPolicyIds.length) {
        policyId = this.insuranceTotal.similarPolicyIds[0];
      } else if (!!this.insuranceTotal.expiredPolicyIds.length) {
        policyId = this.insuranceTotal.expiredPolicyIds[0];
      }
      return policyId;
    },
    statusColor() {
      return this.insuranceTotal.compliant ? 'compliant' : '';
    },
    requirementText() {
      let cov =
        !this.insuranceTotal.adjustedAggregateRequirement &&
        this.insuranceTotal.adjustedPerOccurrenceRequirement > 0
          ? this.insuranceTotal.adjustedPerOccurrenceRequirement
          : this.insuranceTotal.adjustedAggregateRequirement;

      const reason = this.insuranceTotal.requirementAdjustment
        ? this.insuranceTotal.requirementAdjustment.adjustmentReason
        : '';
      const coverage = this.formatRequirement(
        this.insuranceTotal.insuranceType,
        cov,
        reason
      );
      const deductible = this.formatRequirement(
        this.insuranceTotal.insuranceType,
        this.insuranceTotal.adjustedDeductibleRequirement,
        reason
      );
      return `(${coverage}/${deductible})`;
    },
    showModifiedFlag() {
      const sublimitsAdjustment = this.insuranceTotal.subrequirements.some(
        el =>
          el.type === 'SUB_LIMIT' &&
          el.meta &&
          el.meta.adjustmentValue !== undefined &&
          el.meta.adjustmentValue !== null
      );
      return (
        this.insuranceTotal.aggregateAdjustment ||
        this.insuranceTotal.perOccurrenceAdjustment ||
        this.insuranceTotal.deductibleAdjustment ||
        (this.insuranceTotal.requirementAdjustment &&
          (this.insuranceTotal.requirementAdjustment.adjustmentReason ===
            'EXEMPT' ||
            this.insuranceTotal.requirementAdjustment.adjustmentReason ===
              'CLIENT_RISK' ||
            this.insuranceTotal.requirementAdjustment.adjustmentReason ===
              'CLIENT_BILLED')) ||
        sublimitsAdjustment
      );
    },
    getExpiredCoverageId() {
      return this.insuranceTotal.futureComplianceChanges.find(
        f =>
          !f.compliance &&
          f.linkType === 'Coverage' &&
          f.change === 'Deactivate'
      ).linkId;
    },
  },
  methods: {
    formatRequirement,
    async downloadCertificate() {
      this.loadingCoi = true;
      try {
        let resp = await api.getDocumentId({
          correlationId: this.currentPolicyId,
          vendorId: this.contract.vendorId,
        });
        const coi = resp.data
          ?.filter(x => x.type === 'COI')
          ?.sort((a, b) => new Date(b.uploaded) - new Date(a.uploaded))[0];
        let { data } = await downloadDocument(coi.id);
        window.open(data, '_self');
      } catch (error) {
        this.$logger.error(error);
      } finally {
        this.loadingCoi = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.agreement-total {
  margin: 8px 8px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid rgb(205, 219, 222);
  border-radius: 6px;
  min-height: 60px;
  width: 570px;
  position: relative;
  display: inline-flex;

  .compliance-sidebar {
    height: 100%;
    top: 0;
    border-radius: 4px 0px 0px 4px;
    width: 8px;
    position: absolute;
    left: 0px;
  }

  .agreement-total-details {
    width: 100%;

    .app-alert {
      width: 32rem;
    }

    .record-notification {
      padding: 0 0 0 16px;

      .record-notification-content {
        height: 58px;
        padding-right: 16px;

        &.pointer {
          cursor: pointer;
        }
      }
    }
  }
  .compliance-status {
    background: linear-gradient(180deg, #f24f4d 0%, #d13d3b 100%);

    &.compliant {
      background: linear-gradient(180deg, #15b797 0%, #1f9880 100%);
      color: #1f9880;
    }

    &.pending {
      background: linear-gradient(180deg, #cccccc 0%, #a3a3a3 100%);
      color: #a3a3a3;
    }

    &.non-compliant {
      background: linear-gradient(180deg, #f24f4d 0%, #d13d3b 100%);
      color: #d13d3b;
    }

    h2 {
      color: black;
    }
  }
}
</style>
