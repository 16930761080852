<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on }">
      <AppButton class="ml-2" variation="icon" v-on="on">
        <i class="fal fa-ellipsis-h" />
      </AppButton>
    </template>
    <v-list class="py-0">
      <v-list-item link>
        <v-list-item-title class="aon-teal--text">
          <ContractRequirementsDialog :contract="contract" :vendor="vendor" />
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ContractRequirementsDialog from './ContractRequirementsDialog';
import { ACCEPTED, PENDING } from '@/lib/constants/contract-statuses';

export default {
  name: 'VendorContractActions',
  components: {
    ContractRequirementsDialog,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    vendor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      acceptedStatus: ACCEPTED,
      pendingStatus: PENDING,
    };
  },
  computed: {
    contractIsCurrent() {
      return (
        this.contract.evergreen || new Date() < new Date(this.contract.cease)
      );
    },
  },
};
</script>

<style lang="scss">
.insurance-actions {
  .theme--light.v-list-item--link:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: $aon-teal !important;
  }
}
</style>
