<template>
  <span>
    {{ `${title}${computedValue}` }}
  </span>
</template>
<script>
import { formatRequirement } from '@/lib/helpers';
export default {
  name: 'SubLimitsListItemLabel',
  props: {
    insuranceType: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedValue() {
      let _value = '';
      switch (this.type) {
        case 'AMOUNT':
          if (this.value && !isNaN(Number(this.value))) {
            _value = `: ${this.formatRequirement(
              this.insuranceType,
              this.value
            )}`;
          }
          break;
        case 'BOOL':
        case 'TEXT':
        default:
          break;
      }
      return _value;
    },
  },
  methods: {
    formatRequirement,
  },
};
</script>
