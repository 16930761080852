<template>
  <div class="vendor-list">
    <div class="d-flex justify-space-between align center pb-4">
      <div>
        <v-text-field
          v-model="vendorSearchName"
          append-icon="fa-search"
          class="vendor-search"
          clearable
          hide-details
          dense
          outlined
          :placeholder="$t('vendors.search')"
          @click:append="updatePage(1)"
          @click:clear="clearSearch"
          @keyup.enter="performSearch"
        />
      </div>
    </div>

    <Alert
      v-if="error"
      class="mx-auto mb-8"
      :title="$t('vendors.listError')"
      persistent
      type="error"
    />
    <div class="vendor-table-container elevation-1">
      <v-data-table
        v-model="selected"
        class="vendor-list-table"
        :loading="loading"
        :headers="tableHeaders"
        :items="list"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :expanded.sync="expanded"
        item-key="id"
        hide-default-footer
        @click:row="handleRowClick"
      >
        <template #[`item.vendorName`]="{ value, item }">
          <div class="vendor-name">
            <i class="fal fa-angle-right mr-3"></i> {{ value }}
          </div>
          <div class="vendor-id ml-6">
            <small>
              {{
                item.clientInternalVendorId
                  ? 'ID: ' + item.clientInternalVendorId
                  : null
              }}
            </small>
          </div>
        </template>
        <template #[`item.vendorEmail`]="{ item, value, index }">
          <div class="vendor-email" @click="handleRowClick(index)">
            {{ item.vendorContactFirstName }}
            {{ item.vendorContactLastName }}
          </div>
          <div>
            <a :href="`mailto:${value}`" target="_blank" @click.stop>
              {{ value }}
            </a>
          </div>
        </template>
        <template #[`item.vendorAddress`]="{ value }">
          <div class="vendor-address">
            <div>{{ formatAddressLine(value, 1) }}</div>
            <div>{{ formatAddressLine(value, 2) }}</div>
          </div>
        </template>
        <template #[`item.status`]="{ item, value }">
          <div class="d-flex align-center">
            <Status :color="statusColor(value)" class="mx-4">
              {{ $t(`vendorStatuses.${value}`) }}
            </Status>
            <v-tooltip
              v-if="item.hasAdjustment"
              color="aon-peacock"
              bottom
              open-on-hover
              class="text-center"
            >
              <template #activator="{ on }">
                <v-icon class="mb-1" size="0.75em" color="warning" v-on="on">
                  fa-flag
                </v-icon>
              </template>
              <span>{{ $t('vendors.adjustedRequirement') }}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </div>
        </template>
        <template #expanded-item="{ headers, item }">
          <td
            class="vendor-list__item-expand px-12 py-6 pt-2"
            :colspan="headers.length"
          >
            <VendorData
              :key="`contracts-${item.id}`"
              :vendor="item"
              :notification-params="notificationParams"
            />
          </td>
        </template>
      </v-data-table>
      <v-pagination
        v-if="pageCount > 1"
        total-visible="20"
        :value="page"
        :length="pageCount"
        @input="updatePage"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VendorData from './VendorData';
import { MAX_PAGE_SIZE } from '@/api/vendors';
import Status from '@aon/cfs-components/src/components/Status';

export default {
  name: 'VendorList',
  components: {
    Status,
    VendorData,
  },
  data() {
    return {
      error: false,
      expanded: [],
      loading: false,
      page: 0,
      reloadTimer: null,
      heatMapFilters: {},
      selected: [],
      vendorIdFilter: null,
      filters: {},
      vendorSearchName: '',
      vendorSearchParam: '',
      watchQuery: true,
    };
  },
  computed: {
    ...mapGetters('vendors', ['getPage']),
    ...mapState('vendors', ['pageCount', 'pages', 'pageSize']),
    notificationParams() {
      return this.filters.customFilters?.includes('VENDOR_ID_FILTER')
        ? {
            active: true,
            ...this.vendorIdFilter,
          }
        : {};
    },
    itemsPerPage() {
      return this.pageSize || MAX_PAGE_SIZE;
    },
    list() {
      return this.loading ? [] : this.getPage(this.page);
    },
    tableHeaders() {
      return [
        {
          text: this.$t('vendors.listHeaders.name'),
          value: 'vendorName',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('vendors.listHeaders.email'),
          value: 'vendorEmail',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('vendors.listHeaders.address'),
          value: 'vendorAddress',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('vendors.listHeaders.status'),
          value: 'status',
          width: '30%',
        },
      ];
    },
    query() {
      return this.$route.query;
    },
    newPolicyPdf() {
      return {};
    },
  },
  watch: {
    async query() {
      if (this.watchQuery) {
        this.$forceUpdate();
        this.$router.go(0);
      }
    },
  },
  async created() {
    this.checkQuery();

    this.$AppEventBus.$on('updatedContracts', () => this.getVendors(this.page));
    this.$AppEventBus.$on('reloadVendors', backgroundRefresh => {
      this.getVendors(this.page, backgroundRefresh);
    });
    this.$AppEventBus.$on(
      'delayedLazyReloadVendors',
      this.delayedLazyReloadVendors
    );

    this.$AppEventBus.$on('newVendor', this.handleNewVendor);
    this.$AppEventBus.$on('vendorUpdated', this.handleVendorUpdated);
  },
  beforeDestroy() {
    clearTimeout(this.reloadTimer);
  },
  methods: {
    async checkQuery() {
      this.updatePage(1);
    },
    formatAddressLine(address, line) {
      if (!address) return;
      return line === 1
        ? `${address.addressLine1}${
            address.addressLine2 ? ' ' + address.addressLine2 : ''
          }${address.addressLine3 ? ' ' + address.addressLine3 : ''}`
        : `${address.locality}${address.region ? ', ' + address.region : ''} ${
            address.postalCode
          }`;
    },
    delayedLazyReloadVendors() {
      this.reloadTimer = setTimeout(() => {
        this.getVendors(this.page, true);
      }, 3500);
    },
    async getVendors(currentPage = 1, backgroundRefresh = false) {
      this.error = false;
      this.loading = !backgroundRefresh;

      try {
        this.expanded = [];
        const params = {
          currentPage,
          pageSize: this.itemsPerPage,
          statuses: this.filters.vendorStatuses,
        };

        if (this.filters.customFilters) {
          params.vendorId = this.vendorIdFilter?.vendorId;
          params.exposureRisks = [this.heatMapFilters.exposureRisk];
          params.spendAmountRisks = [this.heatMapFilters.spendAmountRisk];
        }

        if (this.vendorSearchParam.length) {
          params.vendorName = this.vendorSearchParam;
        }

        await this.$store.dispatch('vendors/getVendors', params);
        if (this.filters.customFilters && this.vendorIdFilter?.vendorId) {
          this.expanded = [this.getPage(1)[0]];
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async handleNewVendor() {
      await this.updatePage(this.pageCount || 1);

      // necessary in case page count is updated due to new vendor
      if (this.page !== this.pageCount) {
        this.updatePage(this.pageCount);
      }
    },
    handleRowClick(event = {}) {
      if (!this.expanded.length) {
        this.expanded = [event];
        return;
      }

      if (event.id === this.expanded[0].id) {
        this.expanded = [];
        return;
      }

      this.expanded = [event];
    },
    async handleVendorUpdated() {
      await this.getVendors(this.page);
    },
    async updatePage(page) {
      this.page = page;
      await this.getVendors(page);
    },
    clearSearch() {
      this.vendorSearchName = '';
      this.performSearch();
    },
    performSearch() {
      this.vendorSearchParam = this.vendorSearchName || '';
      this.updatePage(1);
    },
    statusColor(value) {
      let color = 'success';
      if (value === 'NON_COMPLIANT_INSURANCE') {
        color = 'error';
      } else if (
        value === 'PENDING_CONTRACT_ACCEPTANCE' ||
        value === 'PENDING_REGISTRATION' ||
        value === 'PENDING_ADJUSTMENT_REQUEST'
      ) {
        color = 'warning';
      } else if (value === 'INACTIVE') {
        color = 'aon-gray-04';
      }
      return color;
    },
    newPolicy() {
      return {
        carrier: null,
        insuranceType: null,
        vendorId: null,
        coverage: null,
        deductible: 0,
        perOccurrenceCoverage: 0,
        effectiveDate: null,
        expirationDate: null,
        subrequirements: [],
      };
    },
  },
};
</script>

<style lang="scss">
.add-contract-btn {
  min-width: 120px;
  max-height: 44px;
}
.vendor-list {
  [class$='-chip'] {
    color: white;
    background: $aon-teal !important;
  }

  .vendor-list-select {
    width: 115px;
    color: $aon-peacock;

    .v-select__selections {
      color: $aon-peacock;
    }

    .v-input__slot {
      min-height: 2.75rem;
      color: $aon-peacock;

      &:hover {
        fieldset {
          border-width: 2px;
        }
      }

      fieldset {
        border-color: $aon-peacock;
      }
    }

    input::placeholder {
      color: $aon-peacock;
    }

    i {
      font-size: 1.25em;
      color: $aon-peacock;
    }
  }

  .v-pagination {
    background: $white;
    border: $border-gray-05;
    border-top: 0;
    padding: 1.25rem;
    border-radius: 0 0 8px 8px;

    .v-pagination__navigation {
      background: none;
      box-shadow: none;
    }

    i {
      color: $aon-teal;
    }

    .v-pagination__item {
      background: none;
      border-radius: 50%;
      color: $aon-teal !important;
      box-shadow: none;

      &--active {
        background: $aon-teal-lightest !important;
        color: $aon-teal !important;
      }
    }
  }

  .vendor-list-table {
    border: $border-gray-05;
    overflow: hidden;
    color: $aon-gray-02;
    border-radius: 8px 8px 0 0 !important;
  }

  .vendor-name {
    color: $aon-gray-01;

    .fa-angle-right {
      transition: transform 200ms;
      color: $aon-teal;
      font-size: 1.33em;
    }
  }

  .vendor-id {
    line-height: 0.75rem;
    color: $aon-gray-03;
  }

  .vendor-address {
    color: $aon-gray-01;
  }

  .vendor-search {
    max-width: 14rem;
    .fa-times-circle,
    .fa-search {
      font-size: 1.1em;
      font-weight: 500;

      &:hover {
        color: $aon-peacock;
      }
    }
  }

  .vendor-table-container {
    background: none;
    border-radius: 8px;
  }

  .theme--light.v-data-table {
    border-radius: 8px;

    .v-data-table__wrapper {
      .v-data-table-header {
        background-color: $white;

        .v-data-table-header__icon {
          opacity: 1;
          margin-left: 8px;
          font-size: 1rem !important;

          &:before {
            content: '\f077';
          }
        }
      }

      > table {
        > tbody {
          tr:not(:last-child) td:not(.v-data-table__mobile-row) {
            border-color: $border-gray-05;
          }

          > tr {
            cursor: pointer;

            td {
              font-size: 1rem;
              height: 56px;
            }

            &:hover {
              &:not(.v-data-table__expanded__content) {
                &:not(.v-data-table__empty-wrapper) {
                  background: $aon-gray-08;
                }
              }
            }
          }
        }

        > thead {
          > tr {
            &:last-child {
              > th {
                font-size: 1rem;
                height: 4rem;
                background: $white;
                color: $aon-gray-01;
                border-bottom: $border-gray-05;
                border-right: $border-gray-05;

                &:last-child {
                  border-right: none;
                }

                &.active {
                  box-shadow: inset 0px -4px 0 0px $aon-peacock;
                  color: $aon-peacock;

                  i {
                    color: $aon-peacock;
                  }
                }
              }
            }
          }
        }
      }
    }

    .v-data-table__expanded {
      &.v-data-table__expanded__row {
        background: $aon-gray-08;

        .text-start {
          border-bottom: 0;
        }

        .fa-angle-right {
          transform: rotate(90deg);
        }
      }

      &.v-data-table__expanded__content {
        background: $aon-gray-08;
        box-shadow: none;
      }
    }
  }

  .item-status {
    &.theme--light {
      color: $aon-gray-02;
      background: none;

      &.compliant {
        color: $success-dark;
      }

      &.pending-registration,
      &.inactive {
        color: $aon-gray-02;
      }

      &.pending-contract-acceptance {
        color: $aon-teal-dark;
      }

      &.non-compliant-insurance {
        color: $error-dark;
      }
    }
  }
}
</style>
