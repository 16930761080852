export const ACTIVE = 'ACTIVE';
export const PENDING = 'PENDING';
export const ACCEPTED = 'ACCEPTED';
export const DECLINED = 'DECLINED';
export const TERMINATED = 'TERMINATED';

const CONTRACT_STATUSES = {
  ACTIVE,
  PENDING,
  ACCEPTED,
  DECLINED,
  TERMINATED,
};
Object.freeze(CONTRACT_STATUSES);

export default CONTRACT_STATUSES;
