<template>
  <Alert
    class="mt-4 mb-0 rounded outlined record-notification"
    type="warning"
    persistent
    no-shadow
  >
    <div class="notification-border"></div>
    <div class="d-flex align-center">
      <span>{{ statusText.pending }}</span>
      <RequestsModal
        v-model="modal"
        :title="statusText.title"
        :sub-title="contract.name"
        :meta="vendor.name"
        :activator-props="{ type: 'ghost', class: 'ml-auto' }"
        :activator-text="$t('contractRequest.view')"
      >
        <div>
          <span class="d-block label mb-2">
            {{ statusText.reason }}
          </span>
          <p>
            {{ contract.pendingReason }}
          </p>
          <div class="d-flex align-center">
            <AppButton
              class="mr-2"
              :loading="accepting"
              :disabled="denying"
              @click="accept"
            >
              {{ $t('contractRequest.accept') }}
            </AppButton>
            <AppButton
              variation="ghost"
              :loading="denying"
              :disabled="accepting"
              @click="deny"
            >
              {{ $t('contractRequest.deny') }}
            </AppButton>
          </div>
        </div>
      </RequestsModal>
    </div>
  </Alert>
</template>

<script>
import snacktime from '@aon/cfs-utils/lib/Snacktime';
import Alert from '@aon/cfs-components/src/components/Alert';
import RequestsModal from '@aon/cfs-components/src/components/RequestsModal';
import { acceptContractRequest, denyContractRequest } from '@/api/contracts';

export default {
  name: 'ContractRequest',
  components: { Alert, RequestsModal },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    vendor: {
      type: Object,
      required: true,
    },
    notificationParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      modal: false,
      accepting: false,
      denying: false,
    };
  },
  computed: {
    statusText() {
      return this.$t(
        `contractRequest.pendingStatus.${this.contract.pendingStatus}`
      );
    },
  },
  created() {
    this.modal =
      this.notificationParams.active &&
      this.notificationParams.contractId === this.contract.id;
  },
  methods: {
    async accept() {
      this.accepting = true;
      await this.submit(true);
      this.accepting = false;
    },
    async deny() {
      this.denying = true;
      await this.submit(false);
      this.denying = false;
    },
    async submit(accepted) {
      try {
        const request = accepted ? acceptContractRequest : denyContractRequest;
        await request(this.contract.id);

        snacktime({
          message: this.statusText[`${accepted ? 'accept' : 'deny'}Success`],
          type: 'success',
        });

        this.modal = false;

        this.updateContracts();

        this.$AppEventBus.$emit('delayedLazyReloadVendors');
      } catch (error) {
        this.$logger.error(error);

        let message = this.statusText[`${accepted ? 'accept' : 'deny'}Error`];

        if (
          error.response.data.includes?.(
            'contract request cannot be responded to if none exist'
          )
        ) {
          message = this.$t('contractRequest.requestResponseError');
          this.modal = false;
          this.updateContracts();
        }

        snacktime({
          message,
          type: 'error',
        });
      }
    },
    updateContracts() {
      this.$AppEventBus.$emit('updatedContracts');
    },
  },
};
</script>
