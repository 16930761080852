var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vendor-list"},[_c('div',{staticClass:"d-flex justify-space-between align center pb-4"},[_c('div',[_c('v-text-field',{staticClass:"vendor-search",attrs:{"append-icon":"fa-search","clearable":"","hide-details":"","dense":"","outlined":"","placeholder":_vm.$t('vendors.search')},on:{"click:append":function($event){return _vm.updatePage(1)},"click:clear":_vm.clearSearch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.performSearch.apply(null, arguments)}},model:{value:(_vm.vendorSearchName),callback:function ($$v) {_vm.vendorSearchName=$$v},expression:"vendorSearchName"}})],1)]),(_vm.error)?_c('Alert',{staticClass:"mx-auto mb-8",attrs:{"title":_vm.$t('vendors.listError'),"persistent":"","type":"error"}}):_vm._e(),_c('div',{staticClass:"vendor-table-container elevation-1"},[_c('v-data-table',{staticClass:"vendor-list-table",attrs:{"loading":_vm.loading,"headers":_vm.tableHeaders,"items":_vm.list,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"expanded":_vm.expanded,"item-key":"id","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.vendorName",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{staticClass:"vendor-name"},[_c('i',{staticClass:"fal fa-angle-right mr-3"}),_vm._v(" "+_vm._s(value)+" ")]),_c('div',{staticClass:"vendor-id ml-6"},[_c('small',[_vm._v(" "+_vm._s(item.clientInternalVendorId ? 'ID: ' + item.clientInternalVendorId : null)+" ")])])]}},{key:"item.vendorEmail",fn:function(ref){
var item = ref.item;
var value = ref.value;
var index = ref.index;
return [_c('div',{staticClass:"vendor-email",on:{"click":function($event){return _vm.handleRowClick(index)}}},[_vm._v(" "+_vm._s(item.vendorContactFirstName)+" "+_vm._s(item.vendorContactLastName)+" ")]),_c('div',[_c('a',{attrs:{"href":("mailto:" + value),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(value)+" ")])])]}},{key:"item.vendorAddress",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"vendor-address"},[_c('div',[_vm._v(_vm._s(_vm.formatAddressLine(value, 1)))]),_c('div',[_vm._v(_vm._s(_vm.formatAddressLine(value, 2)))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"d-flex align-center"},[_c('Status',{staticClass:"mx-4",attrs:{"color":_vm.statusColor(value)}},[_vm._v(" "+_vm._s(_vm.$t(("vendorStatuses." + value)))+" ")]),(item.hasAdjustment)?_c('v-tooltip',{staticClass:"text-center",attrs:{"color":"aon-peacock","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mb-1",attrs:{"size":"0.75em","color":"warning"}},on),[_vm._v(" fa-flag ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('vendors.adjustedRequirement')))])]):_vm._e(),_c('v-spacer')],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"vendor-list__item-expand px-12 py-6 pt-2",attrs:{"colspan":headers.length}},[_c('VendorData',{key:("contracts-" + (item.id)),attrs:{"vendor":item,"notification-params":_vm.notificationParams}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"total-visible":"20","value":_vm.page,"length":_vm.pageCount},on:{"input":_vm.updatePage}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }