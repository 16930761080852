<template>
  <div class="vendors">
    <h2 class="aon-peacock--text pt-7 pb-6">{{ $t('vendors.pageTitle') }}</h2>
    <v-row>
      <v-col>
        <VendorList :key="`contractsUpdatedCount_${contractsUpdatedCount}`" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VendorList from '@/components/Vendors/VendorList.vue';

export default {
  name: 'Vendors',
  components: { VendorList },
  data() {
    return {
      snackbar: false,
      contractsUpdatedCount: 0,
    };
  },
  created() {
    this.contractsUpdatedCount += 1;
  },
};
</script>
