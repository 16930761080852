<template>
  <v-list lines="one">
    <v-list-item v-for="(item, i) in subLimits" :key="`subreq_${i}`">
      <v-list-item-subtitle>
        <SubLimitsListItemLabel
          :insurance-type="insuranceType"
          :type="formatSubLimitsItemType(item)"
          :title="$t(`vendors.SUB_LIMIT.${insuranceType}.${item.name}`)"
          :value="item.value"
        />
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>
<script>
import { formatRequirement } from '@/lib/helpers';
import SubLimitsListItemLabel from './SubLimitsListItemLabel.vue';
export default {
  name: 'SubLimitsList',
  components: { SubLimitsListItemLabel },
  props: {
    insuranceType: {
      type: String,
      required: true,
    },
    subLimits: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  methods: {
    formatRequirement,
    formatSubLimitsItemType(s) {
      return s?.meta?.typeValue || '';
    },
  },
};
</script>
<style scoped>
::v-deep .v-list-item {
  padding: 0;
  min-height: 25px;
}
</style>
