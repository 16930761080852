import axios from 'axios';
import download from 'downloadjs';

const baseUrl = 'api/insurance-policies';

export function getVendorInsuranceTotals(vendorId) {
  return axios.get(`api/vendors/${vendorId}/insurance-totals`);
}

export function getVendorInsuranceTotalsMigrated(vendorId) {
  return axios.get(`api/vendors/${vendorId}/insurance-totals-v2`);
}

export function getInsuranceRequirements({ vendorId }) {
  return axios.get('api/insurance-requirements', {
    params: { vendorId },
  });
}

export function getCoverages({ vendorId, tenantId }) {
  return axios.get(`api/vendors/${vendorId}/insurance-coverages`, {
    params: { tenantId },
  });
}

export function getPolicies({ vendorId, type, tenantId }) {
  return axios.get(`api/vendors/${vendorId}/insurance-policies`, {
    params: { type, tenantId },
  });
}

export function getInsurancePolicy(policyId) {
  return axios.get(`${baseUrl}/${policyId}`);
}

/**
 * @param {string} correlationId
 * @param {string} tenantId
 */
export function getDocuments(correlationId, tenantId) {
  return axios.get('api/documents', {
    params: { correlationId, tenantId },
  });
}

/**
 * @param {string} id
 * @param {string} tenantId
 */
export function downloadDocument(id, tenantId) {
  return axios.get(`api/documents/${id}/download`, {
    params: { tenantId },
  });
}

export function downloadAllDocuments(vendorId, insuranceType) {
  return axios.get(`api/documents/download`, {
    params: {
      vendorId,
      insuranceType,
    },
  });
}

export function downloadFromS3(file) {
  const a = axios.create();
  a.get(file.url, {
    responseType: 'blob',
  }).then(res => {
    const { headers, data } = res;
    download(data, file.fileName, headers['content-type']);
  });
}
